@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: fit-content;
  min-width: 100%;
  padding: 16px;
  display: grid;
  justify-content: space-between;
  border-radius: 20px;
  background-color: $blue;
  @include text-16;
  gap: 20px;
  align-items: center;
  cursor: pointer;
}
