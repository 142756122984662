@import 'src/styles/variables';
@import 'src/styles/mixins';

.divider {
  height: 1px;
  background-color: $grayBorder;
  border: none;
  outline: none;
  margin: 30px 0;
  width: 100%;

  @include vp-767 {
    margin: 16px 0;
  }
}

.noMargin {
  margin: 0;
}
