@import 'src/styles/variables';
@import 'src/styles/mixins';

.fullScreenLoader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.bigLoaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bigLoader {
  width: 200px;
}

.loader {
  width: fit-content;
}
