@import 'src/styles/variables';
@import 'src/styles/mixins';

.option {
  transition: all 0.3s;
  width: 100%;
  cursor: pointer;
  user-select: none;
  padding: 12px 16px;
  @include text-16;
  user-select: none;
  background-color: transparent;
  border-radius: 16px;
  display: flex;
  gap: 8px;
  align-items: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
}
