@import 'src/styles/variables';
@import 'src/styles/mixins';

.subtitle {
  @include text-16;
}

.walletSelect {
  background-color: #1d1f24;
  border-radius: 18px;
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.balance {
  @include text-16;
  color: $gray;
  position: absolute;
  right: 16px;
  top: 16px;
}

.valueError {
  @include text-16;
  color: $primary;
  position: absolute;
  right: 16px;
  top: 16px;
}

.max {
  @include text-16;
  color: $primary;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 6px;
}

.inputTitle {
  @include text-16;
}

.walletIcon {
  width: 24px;
  height: 24px;
}

.disclaimer {
  @include text-16;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid #ffffff33;

  &Title {
    @include text-16;
  }

  &Text {
    @include text-14;
    opacity: 0.5;
  }
}

.icon {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.arrowIcon {
  transform: rotate(90deg);
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.actions {
  width: 100%;
}

.inputField {
  position: relative;
  background-color: #1d1f24;
  border: 1px solid #1d1f24;
  border-radius: 18px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  transition: border 0.3s;

  &Active {
    border-color: white;
  }

  &Error {
    border-color: $primary;
  }
}

.loader {
  height: 40px;
}

.input {
  color: $white;
  @include text-30;
  font-weight: 700;
  max-width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;

  background: transparent;
  border: none;
  outline: none;

  &:active {
    border: none;
    outline: none;
  }

  &:focus {
    border: none;
    outline: none;
  }

  &Error {
    color: $primary;
  }
}

.networkSelect {
  display: flex;
  align-items: center;
  gap: 6px;
}

.optional {
  cursor: pointer;
}

.down {
  transform: rotate(90deg);
}

.up {
  transform: rotate(270deg);
}

.dropdown {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  background-color: #1d1f24f0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  padding: 8px;
  backdrop-filter: blur(20px);
  z-index: 1;
}

.dropdownItem {
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  border-radius: 16px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.wallet {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px;
  background-color: #0e0e0e;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 16px;
}

.walletDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.screenCard {
  padding: 20px;
  background-color: #0e0e0e;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  width: 100%;
}

.pseudoButton {
  padding: 0;
}
