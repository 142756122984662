button.button {
  padding: 16px;
  width: 56px;
  height: 56px;
  min-width: 56px;
  border-radius: 18px;
}

.icon {
  width: 100%;
  height: 100%;
}
