@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.label {
  margin-bottom: 10px;
  @include text-16;
}

.inputField {
  @include text-16;
  background-color: $blue;
  color: $white;
  border-radius: 18px;
  border: 1px solid transparent;
  transition: all 0.3s;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;

  input {
    width: 100%;
    height: 100%;
    padding: 16px 20px;
    padding-right: 0;
    background: transparent;
    border: none;
    outline: none;
    font-family: 'Archivo', sans-serif;
    color: inherit;
    @include text-16;
    height: 56px;

    &[type='password'] {
      letter-spacing: 3px;

      &::placeholder {
        letter-spacing: 3px;
      }
    }

    &::placeholder {
      color: $gray;
    }

    &:-webkit-autofill {
      background-color: $blue !important;
      color: $white !important;
      border: none;
      outline: none;
      -webkit-box-shadow: 0 0 0px 1000px $blue inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: $white;
      @include text-16;
      caret-color: $white;
    }

    &:-internal-autofill-selected {
      background-color: transparent !important;
      @include text-16;
    }
  }

  &:hover,
  &:focus-within {
    border: 1px solid $white;

    input {
      border: none;
      outline: none;
    }
  }

  &.error {
    border: 1px solid $primary;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.readonly {
    pointer-events: none;
  }
}

.insideContent {
  cursor: pointer;
  padding-right: 16px;
}

.showPassword {
  width: 24px;
  height: 24px;
}

button.button {
  @include text-12;
  padding: 0;
}

.errorTag {
  @include text-12;
  color: $primary;
  margin-top: 8px;
}

.description {
  display: flex;
  gap: 8px;
  @include text-16;
  color: $gray;
  margin-top: 4px;

  img {
    min-width: 20px;
    width: 20px;
    min-height: 20px;
    height: 22px;
    object-fit: contain;
    padding-top: 2px;
  }
}

.required {
  color: $primary;
  font-weight: 600;
  @include text-24;
  vertical-align: text-top;
  position: relative;
  top: -4px;
}
