@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  border: 1px solid #ffffff66;
  padding: 20px;
  background: #25252566;
  backdrop-filter: blur(76px);
  border-radius: 23px;

  @include vp-767 {
    padding: 11px;
    border-radius: 11px;
  }
}
