@import 'src/styles/variables';
@import 'src/styles/mixins';

.pagination {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @include vp-767 {
    display: none;
  }
}

.route {
  cursor: pointer;
  user-select: none;
  @include text-14;
  color: $white;
  opacity: 0.5;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.nonClick {
  pointer-events: none;
  opacity: 1;
}

.row {
  display: flex;
  gap: 16px;
}
