@import 'src/styles/variables';
@import 'src/styles/mixins';

.statusTag {
  background-color: rgba(254, 119, 98, 0.2);
  padding: 5px 12px;
  border-radius: 8px;
  color: $primary;
  text-transform: uppercase;
  font-family: 'Archivo', sans-serif;
  backdrop-filter: blur(12px);
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;
  height: fit-content;
}

.icon {
  width: 16px;
  height: 16px;
}

.text {
  @include text-16;
  text-transform: uppercase;
}
