@import 'src/styles/variables';
@import 'src/styles/mixins';

.header {
  width: 100%;
  padding: 22px 0;
  position: sticky;
  top: 0;
  z-index: 30;
  backdrop-filter: blur(50px);
  display: block;

  @include vp-767 {
    display: none;
  }

  @media (max-width: 1280px) {
    padding: 22px 20px;
  }
}

.mainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.leftContainer {
  display: flex;
  align-items: center;
  gap: 50px;
}

.headerMobile {
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #0e0e0e66;
  backdrop-filter: blur(48px);
  z-index: 100;
  height: 72px;
  display: none;

  @include vp-767 {
    display: flex;
  }

  &Right {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &Title {
    @include text-20;
    font-weight: 600;
  }

  &ContactInfo {
    @include text-14;
    color: $gray;
  }
}

.socialMediaButtons {
  display: flex;
  gap: 14px;
}

.socialMediaSection {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 36px;

  @include vp-767 {
    gap: 16px;
  }
}

.helpSection {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.burger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-color: $blue;
  border-radius: 16px;

  &Icon {
    width: 24px;
    height: 24px;
  }
}

.logo {
  display: block;
  width: 50px;
  height: 25px;
}

.img {
  width: 100%;
  height: 100%;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  transition: all 0.3s;
  background-color: #0e0e0e66;
  backdrop-filter: blur(48px);
  z-index: 500;
  padding: 0 20px 36px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  animation: show 0.3s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navLinks {
  display: flex;
  gap: 30px;

  @include vp-767 {
    flex-direction: column;
    gap: 0;
  }
}

.navLink {
  cursor: pointer;
  @include text-16;
  opacity: 0.5;
  transition: all 0.3s;

  &:hover {
    opacity: 1;
  }

  &Active {
    opacity: 1;
  }

  @include vp-767 {
    opacity: 1;
    @include text-24;
    padding: 12px 0;
  }
}

.rightContainer {
  display: flex;
  align-items: center;
  gap: 12px;

  @include vp-1023 {
    flex-direction: column;
    align-items: flex-end;
  }
}

.connectWalletButton {
  padding: 16px;
  border-radius: 18px;
}

.profileIcon {
  width: 24px;
  height: 24px;
}
