@import 'src/styles/variables';
@import 'src/styles/mixins';

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkboxInput {
  appearance: none;
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  background: transparent;
  border: 1.5px solid rgba(103, 109, 117, 1);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  transition:
    border-color 0.3s,
    background-color 0.3s;

  &:checked {
    background-color: $primary;
    border-color: $primary;

    &::after {
      content: '';
      position: absolute;
      top: 4px;
      left: 8px;
      width: 5px;
      height: 10px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &:hover {
    border-color: $primary;
  }
}
