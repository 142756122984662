@import 'src/styles/variables';
@import 'src/styles/mixins';

.smallSphere {
  position: absolute;
  height: 420px;
  width: auto;
  aspect-ratio: 0.93;
  z-index: -1;
}

.bigSphere {
  position: absolute;
  z-index: -1;
  height: 800px;
  aspect-ratio: 1.06;
}
