@import 'src/styles/variables';
@import 'src/styles/mixins';

.accordion {
  display: block;
}

.title {
  font-weight: 500;
  color: $white;
  width: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 20px 0;
}

.icon {
  margin-left: auto;
}

.list {
  overflow: hidden;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  .title {
    @include text-14;
  }
}

.hidden {
  max-height: 0px;
  overflow: hidden;
  padding-bottom: 0;
}

.arrowIcon {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.isOpen {
  transform: rotate(0deg);
}
