@import 'src/styles/variables';
@import 'src/styles/mixins';

.popoverContainer {
  color: $white;
  background-color: rgba(29, 31, 36, 0.4);
  backdrop-filter: blur(60px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  padding: 8px;
  width: fit-content;
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.shareItem {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px;
  cursor: pointer;
  @include text-14;
  font-weight: 600;
  border-radius: 12px;
  transition: all 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  img {
    width: 20px;
    height: 20px;
  }
}

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0;

  div {
    width: 124px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.3);
  }
}
