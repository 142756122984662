@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.input {
  @include text-16;
  padding: 16px 20px;
  background-color: $blue;
  color: $white;
  border-radius: 18px;
  border: 1px solid transparent;
  transition: all 0.3s;
  font-family: 'Archivo', sans-serif;
  resize: none;

  &[type='password'] {
    letter-spacing: 3px;

    &::placeholder {
      letter-spacing: 3px;
    }
  }

  &::placeholder {
    color: $gray;
  }

  &:hover {
    border: 1px solid $white;
  }

  &:active {
    border: 1px solid $white;
  }

  &:focus {
    border: 1px solid $white;
  }
}

.insideContent {
  position: absolute;
  right: 12px;
  top: 34px;
  cursor: pointer;
}

.showPassword {
  width: 24px;
  height: 24px;
}

button.button {
  @include text-12;
  padding: 0;
}

.error {
  border: 1px solid $primary;

  &Tag {
    @include text-12;
    color: $primary;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.readonly {
  pointer-events: none;
}

.description {
  display: flex;
  gap: 8px;

  span {
    @include text-14;
    color: $gray;
  }

  img {
    min-width: 20px;
    width: 20px;
    min-height: 20px;
    height: 22px;
    object-fit: contain;
    padding-top: 2px;
  }
}

.charCount {
  @include text-16;
  opacity: 0.5;
}

.required {
  color: $primary;
  font-weight: 600;
  @include text-24;
  vertical-align: text-top;
  position: relative;
  top: -8px;
}
