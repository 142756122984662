@import 'src/styles/variables';
@import 'src/styles/mixins';

.uploadSection {
  width: 100%;
}

.uploadBox {
  border: 1px dashed #404040;
  width: 100%;
  aspect-ratio: 630 / 280;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.uploadLabel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.hiddenInput {
  display: none;
}

.mediaPreview {
  width: 100%;
  height: 243px;
  object-fit: cover;
  padding: 16px;
}

.uploadText {
  @include text-16;
  color: $white;
  opacity: 0.7;
}

.fileName {
  font-family: 'Archivo', sans-serif;
  @include text-20;
  color: $white;
}

.fileSize {
  @include text-14;
  color: $white;
  opacity: 0.5;
}

.deleteButton {
  padding: 12px 24px;
  background-color: #ff4b4b;
  color: $white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: #ff2b2b;
  }
}

.errorText {
  color: $primary;
  @include text-12;
}

.fileFormats {
  color: $white;
  opacity: 0.5;
  @include text-14;
  text-align: center;
  list-style: none;
  padding: 0;
}

.title {
  color: $white;
  opacity: 1;
  @include text-16;
}

.required {
  color: $primary;
  @include text-24;
  vertical-align: text-top;
  position: relative;
  top: -8px;
}
