@import 'src/styles/variables';
@import 'src/styles/mixins';

.button {
  text-transform: none;
  font-family: 'SpaceGrotesk', sans-serif;
  transition: all 300ms ease-in-out;
  border-radius: 20px;
  box-shadow: none;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 24px;
  @include text-16;
  font-weight: 600;
  background-color: $white;
  cursor: pointer;
  color: $black;

  * {
    font-family: 'SpaceGrotesk', sans-serif;
  }

  &:hover {
    background-color: $black;
    color: $white;
    border: 1px solid $white;

    @include vp-767 {
      box-shadow: none;
      border: 1px solid transparent;
      color: $black;
      background-color: $white;
    }

    svg {
      transition: all 0.3s;

      path {
        transition: all 0.3s;
      }
    }
  }

  svg {
    transition: all 0.3s;

    path {
      // Deleted dependency of colors in case of reusability
      transition: all 0.3s;
    }
  }
}

.outline {
  background-color: $white;
  color: $black;
  border: 1px solid $white;

  &:hover {
    color: $white;
    background-color: $primary;
    border: 1px solid $primary;

    @include vp-767 {
      background-color: $white;
      color: $black;
      border: 1px solid $white;
    }
  }
}

.ghost {
  color: $primary;
  border: 1px solid transparent;
  background-color: transparent;

  &:hover {
    color: $white;
    background-color: transparent;
    border: 1px solid transparent;

    @include vp-767 {
      color: $primary;
      border: 1px solid transparent;
      background-color: transparent;
    }
  }
}

.gray {
  background-color: #ffffff33;
  color: $white;
  border: 1px solid transparent;

  &:hover {
    background-color: #ffffff22;
    border: 1px solid transparent;
  }
}

.blue {
  background-color: $blue;
  color: $primary;
  border: 1px solid $blue;

  &:hover {
    background-color: $blue;
    border: 1px solid $primary;
    color: $primary;

    @include vp-767 {
      background-color: $blue;
      color: $primary;
      border: 1px solid $blue;
    }
  }
}

.black {
  background-color: $black;
  color: $white;
  border: 1px solid $white;

  &:hover {
    background-color: $white;
    color: $black;
    border: 1px solid $white;

    @include vp-767 {
      background-color: $black;
      color: $white;
      border: 1px solid $white;
    }
  }
}

.orange {
  background-color: $primary;
  color: $white;
  border: 1px solid transparent;
  box-shadow: 0px 0px 20px 0px #ffa09199;

  &:hover {
    background-color: $blue;
    color: $primary;
    border: 1px solid $primary;
    box-shadow: none;

    @include vp-767 {
      background-color: $primary;
      color: $white;
      border: 1px solid transparent;
    }
  }
}

.graphite {
  background-color: $dark;
  border: 1px solid transparent;
  color: $white;

  &:hover {
    background-color: $black;
    border: 1px solid transparent;

    @include vp-767 {
      background-color: $dark;
      border: 1px solid transparent;
      color: $white;
    }
  }
}

.small {
  padding: 12px 20px;
  border-radius: 16px;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.fullWidth {
  width: 100%;
}

.noShadow {
  box-shadow: none;
}
