@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  position: relative;
  @include text-14;
}

.select {
  transition: all 0.3s;
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  gap: 20px;
  padding: 16px 20px;
  user-select: none;
  color: $white;
  background-color: $blue;
  border: 1px solid transparent;

  &Active {
    border: 1px solid #fff;
  }
}

.heading {
  @include text-16;
  margin-bottom: 10px;
}

.menu {
  position: absolute;
  margin-top: 8px;
  width: 100%;
  display: grid;
  animation: show 0.3s ease-in-out;
  z-index: 10;
  border-radius: 16px;
  list-style: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgb(29, 31, 36);
  backdrop-filter: 60px;
  padding: 4px;

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.arrow {
  transition: all 0.3s;
  width: 18px;
  height: 18px;
}

.reverse {
  transform: rotate(180deg);
}

.disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.option {
  @include text-16;
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon {
  width: 24px;
  max-width: 24px;
  height: 24px;
  max-height: 24px;
  background-color: transparent;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-weight: 700;
}

.hint {
  @include text-14;
}

.active {
  width: 24px;
  max-width: 24px;
  height: 24px;
  max-height: 24px;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-weight: 700;
}

.anchor {
  display: flex;
  user-select: none;
}
