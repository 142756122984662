@import 'src/styles/variables';
@import 'src/styles/mixins';

.main {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.appContainer {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
}

.pageContainer {
  min-height: 100vh;
  padding-bottom: 120px;
  transition: opacity 1.5s;

  @media (max-width: 1280px) {
    padding: 0 20px 20px;
  }

  @include vp-767 {
    padding-top: 90px;
  }
}

.mainPage {
  padding: 0 0 120px;

  @media (max-width: 1280px) {
    padding: 0 20px 120px;
  }

  @include vp-767 {
    padding: 0 0 120px;
  }
}
