@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: fit-content;
  padding: 16px 20px;
  display: grid;
  justify-content: space-between;
  @include text-16;
  align-items: center;
  gap: 20px;
  background: rgba(255, 255, 255, 0.02);
  cursor: pointer;

  @include vp-767 {
    padding: 0;
    background: transparent;
  }
}

.even {
  background: transparent;
}
