@import 'src/styles/variables';
@import 'src/styles/mixins';

.uploadLabel {
  padding: 32px 16px;
  border-radius: 12px;
  width: 100%;
  height: 172px;
  cursor: pointer;
  position: relative;
  border: 1px dashed #404040;
}

.hiddenInput {
  display: none;
}

.dimmedText {
  @include text-14;
  color: $white;
  opacity: 0.5;
}

.fileInputItem {
  border: 1px solid $white;
  padding: 16px;
  border-radius: 16px;
}

.fileSize {
  @include text-12;
  color: rgba(255, 255, 255, 0.7);
}

.fileName {
  @include text-14;
  color: $white;
  text-wrap: nowrap;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  padding: 0;
}
