@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.solid {
  border-radius: 20px;
  border: 1px solid $grayBorder;
  background: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(48px);
  gap: 0;
}

.heading {
  padding: 16px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  min-width: 800px;

  @include vp-767 {
    min-width: 0px;
    padding: 14px 0;
    min-width: 100%;
  }

  &Solid {
    padding: 30px 20px;
  }
}

.columnTitle {
  @include text-16;
  opacity: 0.5;
  text-align: center;

  @include vp-767 {
    @include text-12;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 800px;

  @include vp-767 {
    @include text-14;
    min-width: 100%;
    padding: 10px;
  }
}
