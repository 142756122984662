@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 1300px;
  height: 1000px;
  position: absolute;
  z-index: -1;

  @include vp-767 {
    width: 800px;
    height: 600px;
  }
}

.wave {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
}
