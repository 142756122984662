@import 'src/styles/variables';
@import 'src/styles/mixins';

.image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 12px;
  min-width: 60px;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.text {
  @include text-16;
  font-weight: 700;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  white-space: wrap;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
