@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: 700;
}
