@import 'src/styles/variables';
@import 'src/styles/mixins';

.tabsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
}
