@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 66px;
  text-transform: uppercase;
  font-family: SpaceGrotesk;

  @include vp-767 {
    @include text-40;
  }
}

.icon {
  transform: rotate(180deg);
}
