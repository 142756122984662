@import 'src/styles/variables';
@import 'src/styles/mixins';

.tab {
  @include text-18;
  padding: 12px 18px;
  border-radius: 40px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  cursor: pointer;
  transition: all 0.3s;
  color: $white;
  border: 1px solid $grayBorder;

  &:hover {
    border-color: $white;
  }
}

.active {
  background-color: $white;
  color: $dark;
  border: 1px solid $white;
}
