@import 'src/styles/variables';
@import 'src/styles/mixins';

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: 'Archivo', sans-serif;
  vertical-align: baseline;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  box-sizing: border-box;
}

a {
  color: $white;
}

@font-face {
  font-family: 'SpaceGrotesk';
  src: url('/fonts/SpaceGrotesk-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SpaceGrotesk';
  src: url('/fonts/SpaceGrotesk-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Archivo';
  src: url('/fonts/Archivo-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Archivo';
  src: url('/fonts/Archivo-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Archivo';
  src: url('/fonts/Archivo-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Archivo';
  src: url('/fonts/Archivo-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  background-color: $dark;
  font-family: 'Archivo', sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
  color: $white;
}

.svg-container:hover .settings-svg path {
  fill: $white;
}

.profile-bg-img {
  background-color: $dark;
  width: 100%;
  min-height: 360px;
  background-image: url('/webp/profile-bg.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}
.desc-input::placeholder {
  position: absolute;
  top: 0;
  top: 16px;
  left: 20px;
}

.desc-input {
  padding-top: 15px !important;
  height: auto !important;
  min-height: 120px !important;
  text-wrap: wrap;
  padding-bottom: 60px !important;
  border: 1px solid #1d1f24 !important;
}

.react-calendar {
  background: transparent !important;
  border: none !important;
  margin-bottom: 20px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.react-calendar__navigation {
  width: 100%;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  color: white !important;
}

.react-calendar__navigation__prev-button:hover,
.react-calendar__navigation__next-button:hover,
.react-calendar__navigation__label:hover,
.react-calendar__navigation__label__labelText--from:hover {
  background: transparent !important;
}

.react-calendar__month-view__days__day:hover {
  background: grey !important;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none !important;
}

.react-calendar__navigation__label__labelText--from {
  color: white !important;
  font-family: 'Archivo';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.react-calendar__month-view__weekdays__weekday {
  color: $white;
  font-family: 'Archivo';
  font-size: 18px;
  font-weight: 600;
  line-height: 12px;
}

.react-calendar__month-view__days__day {
  color: white !important;
  font-family: 'Archivo' !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #ffffff !important;
  opacity: 0.5 !important;
}

.react-calendar__viewContainer {
  width: 300px !important;
}

.react-calendar__navigation__next-button,
.react-calendar__navigation__prev-button {
  font-size: 35px !important;
}

.time-with-scroll {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.time-with-scroll::-webkit-scrollbar {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.days-container {
  border: 2px solid rgba(255, 255, 255, 0.3);
  position: relative;
  z-index: 20;
}

.days-container > div {
  position: relative;
  z-index: 20;
}

.days-container-layer {
  position: absolute;
  z-index: 10;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(60px);
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;

  position: absolute;
  width: 420px;
  height: 228px;
  left: 20px;
  top: 310px;

  background: rgba(29, 31, 36, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(30px);
  border-radius: 16px;

  flex: none;
  order: 5;
  flex-grow: 0;
  z-index: 5;
}

input:focus,
textarea:focus,
.price-input:focus {
  border: 1px solid white;
  overflow: auto !important;
  outline: none !important;

  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.file-formats__list {
  list-style-type: none; /* Remove default bullets */
  padding-left: 0; /* Remove default padding */
}

.file-formats__list li {
  position: relative; /* Needed for absolute positioning of ::before */
  padding-left: 20px; /* Add some space for the custom bullet */
}

.file-formats__list li::before {
  content: '•'; /* Custom bullet character */
  color: $white; /* Bullet color */
  opacity: 50%;
  font-size: 20px; /* Bullet size */
  padding-right: 7px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.react-calendar__tile--active {
  background: grey !important;
}

.react-calendar__tile--now {
  background-color: transparent !important;
}

abbr:where([title]) {
  text-decoration: none !important;
  -webkit-text-decoration: none !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-transform: none; /* Reset any text transformations */
}

w3m-modal {
  z-index: 999999;
}

* {
  box-sizing: border-box;
}

.custom-range {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  background: linear-gradient(
      to right,
      transparent var(--value, 0%),
      #1d1f24 var(--value, 0%)
    ),
    #fe7762;
  border-radius: 20px;
  outline: none;
  transition: all 0.3s;
  opacity: 1;
  border: none;

  &:focus {
    border: none;
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    display: none;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    display: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 4px solid #1d1f24;
}

.custom-slide {
  box-sizing: border-box;
}

.modal-opened {
  overflow: hidden;
  padding-right: 8px;

  @include vp-767 {
    padding-right: 0;
  }
}

.stub-opened {
  @include vp-767 {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }
}

.swiper-pagination {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
}

.pane {
  width: 100%;
  max-width: none !important;
  background-color: #0e0e0e33 !important;
  backdrop-filter: blur(100px) !important;
}

.content {
  width: 100%;

  img,
  video {
    width: 630px;
    height: 316px;
    object-fit: contain;
    margin-bottom: 28px;

    @include vp-767 {
      width: 100%;
    }
  }

  h2 {
    @include text-30;
    font-weight: 600;
    margin-bottom: 16px;
    margin-top: 28px;

    @include vp-767 {
      @include text-20;
    }

    strong {
      @include text-30;
      font-weight: 600;

      @include vp-767 {
        @include text-20;
      }
    }

    b {
      @include text-30;
      font-weight: 600;

      @include vp-767 {
        @include text-20;
      }
    }
  }

  h3 {
    @include text-24;
    font-weight: 600;
    margin-bottom: 16px;
    margin-top: 28px;

    @include vp-767 {
      @include text-18;
    }

    strong {
      @include text-24;
      font-weight: 600;

      @include vp-767 {
        @include text-18;
      }
    }

    b {
      @include text-24;
      font-weight: 600;

      @include vp-767 {
        @include text-18;
      }
    }
  }

  h4 {
    @include text-18;
    color: $primary;
    margin-bottom: 16px;

    @include vp-767 {
      @include text-16;
    }

    strong {
      @include text-18;
      color: $primary;
      font-weight: 600;

      @include vp-767 {
        @include text-16;
      }
    }

    b {
      @include text-18;
      color: $primary;
      font-weight: 600;

      @include vp-767 {
        @include text-16;
      }
    }
  }

  p,
  ul,
  ol {
    @include text-18;
    margin-bottom: 16px;

    @include vp-767 {
      @include text-14;
    }
  }

  ul {
    list-style: disc inside none;
  }

  a {
    text-decoration: underline;
    color: $gray;
  }

  em {
    @include text-18;
    color: $primary;

    @include vp-767 {
      @include text-14;
    }
  }

  ol {
    list-style: decimal inside none;
  }

  i {
    @include text-18;
    font-style: italic;

    @include vp-767 {
      @include text-14;
    }
  }

  b {
    @include text-18;
    font-weight: 600;

    @include vp-767 {
      @include text-14;
    }
  }

  strong {
    @include text-18;
    font-weight: 600;

    @include vp-767 {
      @include text-14;
    }
  }
}
