@import 'src/styles/variables';
@import 'src/styles/mixins';

.alert {
  display: flex;
  gap: 8px;

  span {
    @include text-14;
    color: $primary;
  }

  img {
    min-width: 20px;
    width: 20px;
    min-height: 20px;
    height: 22px;
    object-fit: contain;
    padding-top: 2px;
  }
}
