@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: flex;
  position: relative;
  gap: 10px;
  padding: 0;
  background-color: $blue;
  border-radius: 20px;
}

.userAvatarContainer {
  padding: 10px 12px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background-color: var(--dark-gray);
  border-radius: 18px;
  height: 56px;
  width: 144px;
}

.accountText {
  width: 75px;
  max-width: 75px;
  color: $white;
  @include text-14;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.userCard {
  width: 272px;
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  z-index: 40;

  &Wrapper {
    padding-top: 16px;
    position: absolute;
    right: 0;
    top: 50px;
  }
}

.userCardContent {
  backdrop-filter: blur(10px);
  padding: 6px;
  gap: 6px;
  border-radius: 20px;
  background: rgba(29, 31, 36, 0.9);
}

.balanceInfo {
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 15px;
  background-color: $black;
  border-radius: 14px;
}

.balanceRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.balanceLabel {
  @include text-14;
  opacity: 0.5;
}

.swapButton {
  padding: 12px 20px;
}

.accountBadge {
  display: flex;
  flex-direction: row;
  padding: 6px 8px;
  gap: 4px;
  background-color: $blue;
  border-radius: 32px;
  align-items: center;
}

.statusIndicator {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #82ff9d;
}

.cryptoInfo {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.cryptoIcon {
  width: 18px;
  object-fit: contain;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
}

.balanceAmount {
  @include text-16;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.balanceCurrency {
  @include text-16;
  margin-left: auto;
  font-weight: 600;
}

.actionLinks {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
}

.profileLink,
.disconnectLink {
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.profileIconWrapper,
.disconnectIconWrapper {
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
}

.profileIcon,
.disconnectIcon {
  width: 18px;
  height: 18px;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.linkText {
  @include text-16;
  font-weight: 400;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.profileLink:hover .profileIcon,
.profileLink:hover .linkText,
.disconnectLink:hover .disconnectIcon,
.disconnectLink:hover .linkText {
  opacity: 1;
}
