@import 'src/styles/variables';
@import 'src/styles/mixins';

.footer {
  width: 100%;
  padding: 40px 80px;
  background-color: $black;
  color: $white;
  font-family: 'Archivo', sans-serif;

  @include vp-767 {
    padding: 36px 20px;
  }
}

.mainContainer {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.topSection {
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @include vp-767 {
    flex-direction: column;
    gap: 32px;
  }
}

.logo {
  width: 88px;
  height: 44px;

  @include vp-767 {
    width: 50px;
    height: 25px;
  }
}

.linkColumns {
  display: flex;
  gap: 50px;

  @include vp-767 {
    flex-wrap: wrap;
    gap: 32px;
  }
}

.linkColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.socialMediaSection {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include vp-767 {
    gap: 16px;
  }
}

.socialMediaButtons {
  display: flex;
  gap: 14px;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.contactDetails {
  display: flex;
  gap: 5px;
}

.helpSection {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.divider {
  opacity: 0.2;
}

.actions {
  display: flex;
  justify-content: space-between;
  gap: 50px;

  @include vp-767 {
    flex-direction: column-reverse;
    gap: 32px;
  }
}

.bottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include vp-767 {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}

.bottomLinks {
  display: flex;
  gap: 30px;
  align-items: center;

  @include vp-767 {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 12px;
  }
}

.bottomLink {
  color: rgba(255, 255, 255, 0.4);
  @include text-14;
}

.footerLink {
  @include text-16;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $gray;
    transition: all 0.3s ease;
  }
}

.footerTitle {
  @include text-20;
  font-weight: 600;
}

.footerContactInfo {
  @include text-14;
  color: $gray;
}

.copyright {
  @include text-14;
  color: rgba(255, 255, 255, 0.4);
}
