@import 'src/styles/variables';
@import 'src/styles/mixins';

.modalTitle {
  @include text-30;
  text-transform: none;
  vertical-align: center;
}

.backButton {
  transform: rotate(180deg);
  padding: 0;
}

.icon {
  padding: 0;
}

.modalBody {
  max-height: 600px;
  overflow: auto;
  box-sizing: border-box;

  padding-right: 16px;

  @include vp-767 {
    padding-right: 0;
    padding-bottom: 36px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include vp-767 {
    max-height: calc(100vh - 64px);
  }
}
